import EventEmitter from 'events';

export default class TimeCounter extends EventEmitter {
  constructor(toTime, refreshInterval) {
    super();
    this.setTime(toTime);
    this.interval = setInterval(this.calculate.bind(this), refreshInterval);
  }

  setTime(time) {
    this.countDownDate = new Date(time).getTime();
  }

  calculate() {
    this.now = new Date().getTime();
    this.distance = this.countDownDate - this.now;
    const data = {
      hours: this.getHours(),
      minutes: this.getMinutes(),
      seconds: this.getSeconds(),
    };

    data.filled = {
      hours: this.getFilled(data.hours),
      minutes: this.getFilled(data.minutes),
      seconds: this.getFilled(data.seconds),
    };

    this.emit('change', data);

    if (this.distance < 0) {
      clearInterval(this.interval);
      this.emit('end', {});
    }
  }

  getHours() {
    const value = Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    return this.getParsed(value);
  }

  getMinutes() {
    const value = Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60));
    return this.getParsed(value);
  }

  getSeconds() {
    const value = Math.floor((this.distance % (1000 * 60)) / 1000);
    return this.getParsed(value);
  }

  getFilled(value) {
    if (value < 10) {
      return `0${value}`;
    }

    return value;
  }

  getParsed(value) {
    let parsed = value;
    if (parsed < 0) {
      parsed = 0;
    }
    return parsed;
  }
}
